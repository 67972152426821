.auth-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .card {
        width: 100%;
        border: none !important;
        box-shadow: none !important;
        .card-title {
            font-size: 24pt;
        }
    }
}

.form-control-code {
    letter-spacing: 10pt;
    text-align: center;
    border: 1px solid #dcdce9;
    background: transparent;
    width: 200px;
    margin: auto;
    font-size: 24pt;
    font-weight: 700;
    box-shadow: none;
}

.login-title {
    padding: 50px 5px;
    text-align: center;
    font-size: 35px;
}
